// добавить квадратное уравнение
import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'Вывод по условию',
    text: 'Дано число \\(a\\). Если оно положительное, вывести True. В противном случае ничего не делать',
    note: 'Решить, используя if',
    input: '\\(a\\) - целое',
    output: 'True или ничего',
    examples: [
      {
        input: '5',
        output: 'True'
      },
      {
        input: '0',
        output: ''
      },
      {
        input: '-1',
        output: ''
      }
    ]
  },
  {
    component: Simple,
    name: 'Модуль числа - if-else',
    text: 'Дано число \\(a\\). Если оно положительное, вывести \\(a\\), в противном случае вывести -\\(a\\)',
    note: 'Решить, используя if-else',
    input: '\\(a\\) - целое',
    output: 'Модуль числа',
    examples: [
      {
        input: '5',
        output: '5'
      },
      {
        input: '0',
        output: '0'
      },
      {
        input: '-1',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Модуль числа - тернарный оператор',
    text: 'Дано число \\(a\\). Если оно положительное, вывести \\(a\\), в противном случае вывести -\\(a\\)',
    note: 'Решить, используя тернарный оператор. В Python он записывается в одну строчку: значение1 if условие else значение2. Его можно использовать в выражениях, в том числе писать в print',
    input: '\\(a\\) - целое',
    output: 'Модуль числа',
    examples: [
      {
        input: '5',
        output: '5'
      },
      {
        input: '0',
        output: '0'
      },
      {
        input: '-1',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Изменение по условию',
    text: 'Дано число \\(a\\). Если оно положительное, увеличьте переменную на 1. В конце выведите значение переменной в любом случае',
    note: 'Решить, используя if и +=',
    input: '\\(a\\) - целое',
    output: 'Новое значение \\(a\\)',
    examples: [
      {
        input: '5',
        output: '6'
      },
      {
        input: '0',
        output: '0'
      },
      {
        input: '-1',
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Изменение if-else',
    text: 'Дано число \\(a\\). Если оно положительное, нужно увеличить значение переменной на 1, в противном случае уменьшить на 2. После этого в любом случае вывести значение переменной',
    note: 'Решить, используя if-else, += и -=',
    input: '\\(a\\) - целое',
    output: 'Новое значение \\(a\\)',
    examples: [
      {
        input: '5',
        output: '6'
      },
      {
        input: '0',
        output: '-2'
      },
      {
        input: '-1',
        output: '-3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Изменение с тернарным оператором',
    text: 'Дано число \\(a\\). Если оно положительное, нужно увеличить значение переменной на 1, в противном случае уменьшить на 2. После этого в любом случае вывести значение переменной',
    note: 'Решить, используя += и тернарный оператор',
    input: '\\(a\\) - целое',
    output: 'Новое значение \\(a\\)',
    examples: [
      {
        input: '5',
        output: '6'
      },
      {
        input: '0',
        output: '-2'
      },
      {
        input: '-1',
        output: '-3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Тройное ветвление',
    text: 'Дано целое число \\(a\\). Если оно положительное, нужно увеличить значение переменной на 1, если отрицательное, уменьшить на 2, в противном случае присвоить переменной значение 10. После этого в любом случае вывести значение переменной.',
    note: 'Нужно использовать if-elif-else',
    input: '\\(a\\) - целое',
    output: 'Новое значение \\(a\\)',
    examples: [
      {
        input: '5',
        output: '6'
      },
      {
        input: '0',
        output: '10'
      },
      {
        input: '-1',
        output: '-3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вася, Петя или ничья',
    text: 'Вася и Петя поспорили, кто из них решит больше задач за одинаковое время. Вам даны два числа \\(a\\) и \\(b\\) - количество задач, решенных Васей и Петей соответственно. Выведите 1, если победил Вася, 2, если Петя, и 0, если ничья',
    note: null,
    input: '\\(a\\), \\(b\\) - целые неотрицательные',
    output: '0, 1 или 2',
    examples: [
      {
        input: '20, 15',
        output: '1'
      },
      {
        input: '15, 20',
        output: '2'
      },
      {
        input: '10, 10',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разница решённых задач',
    text: 'Вася и Петя поспорили, кто из них решит больше задач за одинаковое время. Вам даны два числа \\(a\\) и \\(b\\) - количество задач, решенных Васей и Петей соответственно. Выведите, на сколько задач победитель обогнал проигравшего. Если задач решено поровну, выведите 0',
    note: 'Допускается не больше одного сравнения',
    input: '\\(a\\), \\(b\\) - целые неотрицательные',
    output: 'Разница в количестве решённых задач',
    examples: [
      {
        input: '20, 15',
        output: '5'
      },
      {
        input: '20, 25',
        output: '5'
      },
      {
        input: '10, 10',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вася в магазине',
    text: 'Вася пошёл в магазин за мороженым и шоколадкой, но располагал только суммой в \\(r\\) рублей. Если денег не хватит на оба предмета, и будет выбор между мороженым и шоколадкой, Вася выберет мороженое. В магазине Вася увидел, что мороженое стоит \\(m\\) рублей, а шоколадка стоит \\(s\\) рублей. Выведите, что купит Вася (0 - ничего, 1 - шоколад, 2 - мороженое, 3 - и то, и другое) и через пробел сколько денег останется у Васи',
    note: 'Допускается не больше трёх сравнений',
    input: '\\(r\\), \\(m\\) и \\(s\\) - целые неотрицательные числа',
    output: 'Два числа через пробел - что купит и сколько денег останется',
    examples: [
      {
        input: '100, 30, 40',
        output: '3 30'
      },
      {
        input: '100, 30, 70',
        output: '3 0'
      },
      {
        input: '100, 50, 100',
        output: '2 50'
      },
      {
        input: '100, 120, 100',
        output: '1 0'
      },
      {
        input: '100, 120, 120',
        output: '0 100'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимум из двух - if-else',
    text: 'Даны числа \\(a\\) и \\(b\\). Вывести наибольшее из них (любое, если одинаковые)',
    note: 'Решить, используя if-else',
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Максимум из двух',
    examples: [
      {
        input: '5, 6',
        output: '6'
      },
      {
        input: '7, 5',
        output: '7'
      },
      {
        input: '3, 3',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимум из двух - тернарный оператор',
    text: 'Даны числа \\(a\\) и \\(b\\). Вывести наибольшее из них (любое, если одинаковые)',
    note: 'Решить, используя тернарный оператор',
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Максимум из двух',
    examples: [
      {
        input: '5, 6',
        output: '6'
      },
      {
        input: '7, 5',
        output: '7'
      },
      {
        input: '3, 3',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Если \\(a\\) чётное, то увеличить \\(b\\) и \\(c\\) на 1, в противном случае ничего не менять. Затем, в любом случае, если после этого \\(b\\) чётное, а \\(c\\) нечётное, то уменьшить \\(a\\) на 2',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\)',
    examples: [
      {
        input: '4, 5, 6',
        output: '2 6 7'
      },
      {
        input: '4, 3, 5',
        output: '4 4 6'
      },
      {
        input: '7, 6, 5',
        output: '5 6 5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if c подсчётом',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите сколько из них являются положительными',
    note: 'Использовать переменную-счётчик. Допускается не больше трёх сравнений и один вывод в конце',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Количество положительных',
    examples: [
      {
        input: '4, 5, 6',
        output: '3'
      },
      {
        input: '-9, 4, 7',
        output: '2'
      },
      {
        input: '1, 0, -1',
        output: '1'
      },
      {
        input: '-2, -1, 0',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if c подсчётом (несколько условий)',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите, сколько из них чётных, либо заканчивающихся на 7.',
    note: null,
    input: 'Заданы три целых неотрицательных числа \\(a\\), \\(b\\) и \\(c\\)',
    output: 'Количество чётных или с 7 на конце',
    examples: [
      {
        input: '10, 17, 25',
        output: '2'
      },
      {
        input: '3, 7, 9',
        output: '1'
      },
      {
        input: '6, 7, 8',
        output: '3'
      },
      {
        input: '11, 13, 15',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if c суммой',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите сумму только чётных из них',
    note: 'Использовать переменную для накопления суммы. Допускается не больше трёх сравнений и один вывод в конце',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые неотрицательные',
    output: 'Сумма чётных',
    examples: [
      {
        input: '4, 5, 6',
        output: '10'
      },
      {
        input: '9, 4, 7',
        output: '4'
      },
      {
        input: '6, 2, 7',
        output: '8'
      },
      {
        input: '1, 3, 5',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if cо средним',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите среднее арифметическое чисел, оканчивающихся на 7. Если таких нет, вывести 0.0',
    note: 'Использовать две переменные для накопления суммы и количества. Допускается не больше трёх сравнений на подсчёт суммы и количества, и одно сравнение для вывода в конце',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые положительные',
    output: 'Среднее арифметическое чисел',
    examples: [
      {
        input: '7, 17, 37',
        output: '20.333333333333332'
      },
      {
        input: '7, 71, 17',
        output: '12.0'
      },
      {
        input: '13, 27, 15',
        output: '27.0'
      },
      {
        input: '25, 1, 73',
        output: '0.0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if для определения максимума',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите максимальное число (любое, если несколько максимальных)',
    note: 'Использовать переменную для хранения максимума. Допускается не больше двух сравнений и один вывод в конце',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Максимальное число',
    examples: [
      {
        input: '5, 8, 2',
        output: '8'
      },
      {
        input: '3, 6, 6',
        output: '6'
      },
      {
        input: '-1, -2, -3',
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if для определения номера первого максимума',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите порядковый номер максимального числа (первого, если их несколько)',
    note: 'Использовать две переменные для хранения максимума и номера. Допускается не больше двух сравнений и один вывод в конце',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Номер максимального числа и само это число через пробел',
    examples: [
      {
        input: '10, 8, 3',
        output: '1 10'
      },
      {
        input: '3, 6, 6',
        output: '2 6'
      },
      {
        input: '-1, -2, 0',
        output: '3 0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательные if для определения номера последнего минимума',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите порядковый номер минимального числа (последнего, если их несколько)',
    note: 'Использовать две переменные для хранения минимума и номера. Допускается не больше двух сравнений и один вывод в конце',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Номер минимального числа',
    examples: [
      {
        input: '2, 8, 3',
        output: '1'
      },
      {
        input: '6, 6, 7',
        output: '2'
      },
      {
        input: '-5, 4, -7',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разность максимума и минимума',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Выведите разность самого большого и самого маленького числа',
    note: 'Допускается не больше четырёх сравнений на задачу',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Разность максимума и минимума',
    examples: [
      {
        input: '5, 8, 2',
        output: '6'
      },
      {
        input: '3, 5, 6',
        output: '3'
      },
      {
        input: '20, 5, 10',
        output: '15'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимум или минимум по условию',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Если хотя бы одно из них заканчивается на 4, то вывести самое большое число, в противном случае самое маленькое',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые неотрицательные',
    output: 'Максимум или минимум',
    examples: [
      {
        input: '3, 4, 5',
        output: '5'
      },
      {
        input: '5, 6, 7',
        output: '5'
      },
      {
        input: '4, 24, 14',
        output: '24'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сортировка двух - обмен по условию',
    text: 'Даны два числа \\(a\\) и \\(b\\). Если \\(a\\) больше \\(b\\), обменяйте переменные значениями. В конце выведите переменные в любом случае',
    note: 'Можно использовать обмен списочным присваиванием',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\)',
    examples: [
      {
        input: '5, 7',
        output: '5 7'
      },
      {
        input: '8, 1',
        output: '1 8'
      },
      {
        input: '7, 7',
        output: '7 7'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сортировка трёх по неубыванию',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Обменяйте значения переменных таким образом, чтобы стало \\(a \\le b \\le c\\)',
    note: 'Можно использовать обмены списочным присваиванием. Допускается не больше трёх сравнений',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\)',
    examples: [
      {
        input: '5, 8, 2',
        output: '2 5 8'
      },
      {
        input: '3, 5, 6',
        output: '3 5 6'
      },
      {
        input: '-1, -2, -3',
        output: '-3 -2 -1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сортировка трёх по невозрастанию',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Обменяйте значения переменных таким образом, чтобы стало \\(a \\ge b \\ge c\\)',
    note: 'Можно использовать обмены списочным присваиванием. Допускается не больше трёх сравнений',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\)',
    examples: [
      {
        input: '5, 8, 2',
        output: '8 5 2'
      },
      {
        input: '3, 5, 6',
        output: '6 5 3'
      },
      {
        input: '-1, -2, -3',
        output: '-1 -2 -3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Умножение на 2 или -1',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Если их сумма нечётная, то умножить каждое число на 2, в противном случае на -1. Вывести новые значения',
    note: 'Допускается не больше трёх умножений на задачу. Решить, используя один тернарный оператор',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые неотрицательные',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\)',
    examples: [
      {
        input: '1, 2, 3',
        output: '-1 -2 -3'
      },
      {
        input: '2, 3, 4',
        output: '4 6 8'
      },
      {
        input: '3, 3, 3',
        output: '6 6 6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сколько корней у квадратного уравнения?',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\) - коэффициенты квадратного уравнения. Выведите количество корней этого уравнения',
    note: 'Решается через дескриминант',
    input: '\\(a\\), \\(b\\) и \\(c\\) - вещественные числа',
    output: 'Количество корней',
    examples: [
      {
        input: '1.0, 1.0, 1.0',
        output: '0'
      },
      {
        input: '1.0, -4.0, 4.0',
        output: '1'
      },
      {
        input: '1.0, 2.0, -3.0',
        output: '2'
      }
    ]
  }
]
